.App {
  flex: 1;
}

.AgreementApp{
    flex: 1;
    padding-left: 4.25vw;
    padding-right: 4.25vw;  
}

.container{
  padding-left: 4.25vw;
  padding-right: 4.25vw;
}

.App-logo {
  height: 15vh;
}

.bkgnd_bottom{
  width: 100vw;
  height: 3vh;
}

.upgradeslogn{
  color: #1A1A1A;
  font: 15pt bold SFProText-Medium;
}

.payonpc{
    margin-top: 20vh;
    color: #1A1A1A;
    font: 15pt bold SFProText-Medium;
    text-align: center;
}

.headerbkgnd {
  height: 16vh;
  width: 100vw;
  background-color: #F4F6FA;
  display: table-cell;
  text-align: center;
}

.payamount{
  justify-content: center;
  width: 90vw;
  display: flex;
  flex-flow: row;  
  align-items: center;
}

.wechatpaytext{
  margin-left: 2vw;
}

.textcontainer{
  padding-left: 4.25vw;
}

.text1{
  color: #606A80;
  font: 10pt PingFangSC-Regular;
  margin: 1vw;
}

.payitem1{
  width: 27.5vw;
  height: 27.5vw;
  background-color: #FEF7EF;
  border-radius:6px;
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  border-style:solid;
  border-width: 2px;
}

.payitem{
  margin-left: 4.5vmin;
  width: 27.5vw;
  height: 27.5vw;
  background-color: #FEF7EF;
  border-radius:6px;
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  border-style:solid;
  border-width: 2px;
}

.wechatpayicon{
  width: 6.5vw;
  height: 6.5vw;
}

.wechatblank{
  flex: 1;
}

.wechatpay{
  margin-top: 3vh;
  margin-bottom: 3vh;
  padding-left: 3.2vw;
  padding-right: 3.2vw;
  border-style:solid;
  border-width: 2px;
  border-color: #EEEEEE;
  width: 84vw;
  height: 7.5vh;
  border-radius:6px;
  display: flex;
  align-items: center;
  flex-flow: row;  
}

.paybutton{
  width: 91vw;
  height: 7.5vh;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  border-radius:6px;
  color: #ffffff;
  font: 14pt PingFangSC-Semibold;
}

.paydate{
  color: #1A1A1A;
  font: 12pt SFProText-Semibold;
  text-align: center;
}

.oneyear{
    display: flex;
    align-items: flex-end;
    flex-flow: row;  
}

.zhekou{
    margin-left: 1vw;
    color: #FF6213;
    font: 12pt SFProText-Semibold;
    text-align: center;
}

.allmoney{
  
  display: flex;
  align-items: flex-end;
  flex-flow: row;  
}

.payyang{
  color: #FF6213;
  font: bold 14pt SFProText-Semibold;
  margin-bottom: 0.5vh;
}

.paymoney{
  color: #FF6213;
  font: bold 26pt SFProText-Semibold;
}


.homepage-title{
    width: 100vw;
}

.homepage-container{
    display: flex;
    flex-direction: column;
    align-items:center;
    background-color: #FFFFFF;
    height: 100vh;
}

.homepage-logo{
    height: 20vh;
    width: 20vh;
    margin-top: 10vh;
}

.homepage-slogn{
    margin-top: 4vh;
    color: #1A1A1A;
    font: 15pt bold SFProText-Medium;
}

.homepage-dl-android-btn{
    margin-top: 8vh;
    
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items:center;
    
    border-radius:8vw;
    border-style:solid;
    border-width: 1px;
    border-color: #00A5FF;
    
    color: #00A5FF;
    font: 15pt bold SFProText-Medium;

    width: 55vw;
    height: 16vw;
}

.wechat-modal{
    position:absolute;
    width: 100vw;
    height: 100vh;
}

.homepage-dl-android-btn:active{
    background-color: #00A5FF;
    color:#ffffff;
}

.dl_icon{
    width:8vw;
    margin-right: 4vw;
}

.divhomepage2{
    background-color: #28CCD9;
    height:100%;
}

.divhomepage3{
    background-color: #FFD600 ;
    height:100%;
}

.divhomepage4{
    background-color: #DB4537;
    height:100%;
}

.homepageImage{
    width:100%;
}

.beianlink { 
  font: 10pt SFProText-Semibold;
  color: #8fcff1;
  line-height: 50px;
}

.beian {
  position:absolute;
  bottom:0;
}

a{
    text-decoration:none;
}



a:hover{
    -webkit-tap-highlight-color: transparent;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
