


.pdcontainer{
    margin:0;
    padding:0;
}

.containertitle{
    background-color: #00C7FA;
    height: 205px;
    padding-left: 100px;
}

.container2{
    width: 1024px;
    margin:0 auto;
}

  
.titlebar{
    height: 205px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 1024px;
    margin:0 auto;
}

.titlebkgnd{
    position:relative;
    width:737px;
    height:205px;
}

.downloadqrcode{
    position:relative;
    width:133px;
    height:133px;
}

.titlecontainer{
    margin-left: 25px;
    height:133px;
}

.dltitle{
    font-size: 32px;
    color: #FFFFFF;
}

.dlslogn{
    font-size: 18px;
    color: #FFFFFF;
    margin-top: 10px;
}

.dlbcontainer{
    display: flex;
    flex-direction: row;
    background-color: #00C7FF;
    margin-top: 15px;
}

.doloadbtnios{
    width:112px;
    height:33px;
    background: url(https://image.idesktopcal.com/phonedownload/downloadbtn.png) no-repeat no-repeat 0 -33px;
}

.doloadbtnios:hover{
    width:112px;
    height:33px;
    background: url(https://image.idesktopcal.com/phonedownload/downloadbtn.png) no-repeat -112px -33px; display: block; width: 112px; height: 33px;
}

.doloadbtnandroid{
    width:112px;
    height:33px;
    margin-left:10px;
    background: url(https://image.idesktopcal.com/phonedownload/downloadbtn.png) no-repeat no-repeat 0 0;
}

.doloadbtnandroid:hover{
    width:112px;
    height:33px;
    margin-left:10px;
    background: url(https://image.idesktopcal.com/phonedownload/downloadbtn.png) no-repeat -112px 0; display: block; width: 112px; height: 33px;
}

.screentext{
    font-size: 18px;
    color: #000000;
    margin-top: 30px;
    margin-left: 60px;
}

.imagescontainer{
    display: flex;
    flex-direction: row;
    margin-left: 40px;
}

.bottomcontainer{
    margin-top: 40px;
    height: 135px;
    background: #F2F2F2;
}

.aboutcontainer{
    padding-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content:center;
    height: 60px;
}

.imagelist{
    width: 313px;
    height: 526px;
}

.desktopcal{
    font-size: 12px;
    color: #333333;
}

.splittext{
    font-size: 12px;
    color: #333333;
    margin-left: 20px;
    margin-right: 20px;
}

.abouttext{
    font-size: 12px;
    color: #333333;
}

.copyrighttext{
    font-size: 12px;
    text-align: center;
    color: #666666;
}