body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  flex: 1 1;
}

.AgreementApp{
    flex: 1 1;
    padding-left: 4.25vw;
    padding-right: 4.25vw;  
}

.container{
  padding-left: 4.25vw;
  padding-right: 4.25vw;
}

.App-logo {
  height: 15vh;
}

.bkgnd_bottom{
  width: 100vw;
  height: 3vh;
}

.upgradeslogn{
  color: #1A1A1A;
  font: 15pt bold SFProText-Medium;
}

.payonpc{
    margin-top: 20vh;
    color: #1A1A1A;
    font: 15pt bold SFProText-Medium;
    text-align: center;
}

.headerbkgnd {
  height: 16vh;
  width: 100vw;
  background-color: #F4F6FA;
  display: table-cell;
  text-align: center;
}

.payamount{
  justify-content: center;
  width: 90vw;
  display: flex;
  flex-flow: row;  
  align-items: center;
}

.wechatpaytext{
  margin-left: 2vw;
}

.textcontainer{
  padding-left: 4.25vw;
}

.text1{
  color: #606A80;
  font: 10pt PingFangSC-Regular;
  margin: 1vw;
}

.payitem1{
  width: 27.5vw;
  height: 27.5vw;
  background-color: #FEF7EF;
  border-radius:6px;
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  border-style:solid;
  border-width: 2px;
}

.payitem{
  margin-left: 4.5vmin;
  width: 27.5vw;
  height: 27.5vw;
  background-color: #FEF7EF;
  border-radius:6px;
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  border-style:solid;
  border-width: 2px;
}

.wechatpayicon{
  width: 6.5vw;
  height: 6.5vw;
}

.wechatblank{
  flex: 1 1;
}

.wechatpay{
  margin-top: 3vh;
  margin-bottom: 3vh;
  padding-left: 3.2vw;
  padding-right: 3.2vw;
  border-style:solid;
  border-width: 2px;
  border-color: #EEEEEE;
  width: 84vw;
  height: 7.5vh;
  border-radius:6px;
  display: flex;
  align-items: center;
  flex-flow: row;  
}

.paybutton{
  width: 91vw;
  height: 7.5vh;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  border-radius:6px;
  color: #ffffff;
  font: 14pt PingFangSC-Semibold;
}

.paydate{
  color: #1A1A1A;
  font: 12pt SFProText-Semibold;
  text-align: center;
}

.oneyear{
    display: flex;
    align-items: flex-end;
    flex-flow: row;  
}

.zhekou{
    margin-left: 1vw;
    color: #FF6213;
    font: 12pt SFProText-Semibold;
    text-align: center;
}

.allmoney{
  
  display: flex;
  align-items: flex-end;
  flex-flow: row;  
}

.payyang{
  color: #FF6213;
  font: bold 14pt SFProText-Semibold;
  margin-bottom: 0.5vh;
}

.paymoney{
  color: #FF6213;
  font: bold 26pt SFProText-Semibold;
}


.homepage-title{
    width: 100vw;
}

.homepage-container{
    display: flex;
    flex-direction: column;
    align-items:center;
    background-color: #FFFFFF;
    height: 100vh;
}

.homepage-logo{
    height: 20vh;
    width: 20vh;
    margin-top: 10vh;
}

.homepage-slogn{
    margin-top: 4vh;
    color: #1A1A1A;
    font: 15pt bold SFProText-Medium;
}

.homepage-dl-android-btn{
    margin-top: 8vh;
    
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items:center;
    
    border-radius:8vw;
    border-style:solid;
    border-width: 1px;
    border-color: #00A5FF;
    
    color: #00A5FF;
    font: 15pt bold SFProText-Medium;

    width: 55vw;
    height: 16vw;
}

.wechat-modal{
    position:absolute;
    width: 100vw;
    height: 100vh;
}

.homepage-dl-android-btn:active{
    background-color: #00A5FF;
    color:#ffffff;
}

.dl_icon{
    width:8vw;
    margin-right: 4vw;
}

.divhomepage2{
    background-color: #28CCD9;
    height:100%;
}

.divhomepage3{
    background-color: #FFD600 ;
    height:100%;
}

.divhomepage4{
    background-color: #DB4537;
    height:100%;
}

.homepageImage{
    width:100%;
}

.beianlink { 
  font: 10pt SFProText-Semibold;
  color: #8fcff1;
  line-height: 50px;
}

.beian {
  position:absolute;
  bottom:0;
}

a{
    text-decoration:none;
}



a:hover{
    -webkit-tap-highlight-color: transparent;
}
@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}




.pdcontainer{
    margin:0;
    padding:0;
}

.containertitle{
    background-color: #00C7FA;
    height: 205px;
    padding-left: 100px;
}

.container2{
    width: 1024px;
    margin:0 auto;
}

  
.titlebar{
    height: 205px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 1024px;
    margin:0 auto;
}

.titlebkgnd{
    position:relative;
    width:737px;
    height:205px;
}

.downloadqrcode{
    position:relative;
    width:133px;
    height:133px;
}

.titlecontainer{
    margin-left: 25px;
    height:133px;
}

.dltitle{
    font-size: 32px;
    color: #FFFFFF;
}

.dlslogn{
    font-size: 18px;
    color: #FFFFFF;
    margin-top: 10px;
}

.dlbcontainer{
    display: flex;
    flex-direction: row;
    background-color: #00C7FF;
    margin-top: 15px;
}

.doloadbtnios{
    width:112px;
    height:33px;
    background: url(https://image.idesktopcal.com/phonedownload/downloadbtn.png) no-repeat no-repeat 0 -33px;
}

.doloadbtnios:hover{
    width:112px;
    height:33px;
    background: url(https://image.idesktopcal.com/phonedownload/downloadbtn.png) no-repeat -112px -33px; display: block; width: 112px; height: 33px;
}

.doloadbtnandroid{
    width:112px;
    height:33px;
    margin-left:10px;
    background: url(https://image.idesktopcal.com/phonedownload/downloadbtn.png) no-repeat no-repeat 0 0;
}

.doloadbtnandroid:hover{
    width:112px;
    height:33px;
    margin-left:10px;
    background: url(https://image.idesktopcal.com/phonedownload/downloadbtn.png) no-repeat -112px 0; display: block; width: 112px; height: 33px;
}

.screentext{
    font-size: 18px;
    color: #000000;
    margin-top: 30px;
    margin-left: 60px;
}

.imagescontainer{
    display: flex;
    flex-direction: row;
    margin-left: 40px;
}

.bottomcontainer{
    margin-top: 40px;
    height: 135px;
    background: #F2F2F2;
}

.aboutcontainer{
    padding-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content:center;
    height: 60px;
}

.imagelist{
    width: 313px;
    height: 526px;
}

.desktopcal{
    font-size: 12px;
    color: #333333;
}

.splittext{
    font-size: 12px;
    color: #333333;
    margin-left: 20px;
    margin-right: 20px;
}

.abouttext{
    font-size: 12px;
    color: #333333;
}

.copyrighttext{
    font-size: 12px;
    text-align: center;
    color: #666666;
}

.web {

}

.web .header{
    height: 86px; 
    background-color: #027BFA; 
}

.web .header .content{ margin: 0 auto;  } 
.web .header .content .left{ font-size: 34px; color: #FFFFFF; margin-left:20px; padding-top: 18px; font-weight:bold; }


.web .footer{ background-color: #027BFA; height: 400px; width: 100%; margin-top: 100px; }
.web .footer .content{ height: 400px; margin: 20px; }
.web .footer .content .center{ height: 350px; }
.web .footer .content .center .left { float: left; width: 450px }
.web .footer .content .center .left .top{ margin-top: 50px; font-size: 16px; color: #FFFFFF; line-height: 24px; font-weight:bold; }
.web .footer .content .center .left .top1{ margin-top: 30px; font-size: 16px; color: #FFFFFF; line-height: 24px; }
.web .footer .content .bottom{ margin-top: 10px; font-size: 16px; color: #FFFFFF; line-height: 24px;text-align: center; }
.web .footer .content .link { color: #FFFFFF; }
.web .eula{ margin: 20px; }
.web .eula .title { font-weight: bold; font-size: 28px; margin-top: 50px; }
.web .eula .part { font-weight: bold; font-size: 18px; margin-top: 50px; }
.web .eula .text { line-height: 28px; font-size: 16px;; margin-top: 10px; }


