
.web {

}

.web .header{
    height: 86px; 
    background-color: #027BFA; 
}

.web .header .content{ margin: 0 auto;  } 
.web .header .content .left{ font-size: 34px; color: #FFFFFF; margin-left:20px; padding-top: 18px; font-weight:bold; }


.web .footer{ background-color: #027BFA; height: 400px; width: 100%; margin-top: 100px; }
.web .footer .content{ height: 400px; margin: 20px; }
.web .footer .content .center{ height: 350px; }
.web .footer .content .center .left { float: left; width: 450px }
.web .footer .content .center .left .top{ margin-top: 50px; font-size: 16px; color: #FFFFFF; line-height: 24px; font-weight:bold; }
.web .footer .content .center .left .top1{ margin-top: 30px; font-size: 16px; color: #FFFFFF; line-height: 24px; }
.web .footer .content .bottom{ margin-top: 10px; font-size: 16px; color: #FFFFFF; line-height: 24px;text-align: center; }
.web .footer .content .link { color: #FFFFFF; }
.web .eula{ margin: 20px; }
.web .eula .title { font-weight: bold; font-size: 28px; margin-top: 50px; }
.web .eula .part { font-weight: bold; font-size: 18px; margin-top: 50px; }
.web .eula .text { line-height: 28px; font-size: 16px;; margin-top: 10px; }

